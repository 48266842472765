import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  Grid,
  Alert,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import { history } from "&store/store";
import HeroImage from "&assets/images/hero.png";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { colorPalette } from "&config/colors";
import { RootState } from "&store/store";
import {
  TextInput,
  PasswordInput,
} from "&styled/textField/textField.component";
import { SubmitButton } from "&styled/button/button.component";
import  HashirLogo  from "&assets/images/hashir-logo.png";
import { loginActions } from "./login.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const LoginComponent = (props: ReduxProps) => {
  const [error, setError] = useState("");
  const { logIn, isLoggedIn } = props;
 
  useEffect(() => {
    if (isLoggedIn) {
      history.push("/dashboard");
    }
  }, [isLoggedIn]);

  const initialValues = {
    userName: "",
    password: "",
  };
  const validation = Yup.object().shape({
    userName: Yup.string().required("Please provide username"),
    password: Yup.string().required("Please provide password"),
  });

  const handleLoginFormSubmit = async (values: any) => {
    const { payload } = await logIn(values);
    if (typeof payload === "string") {
      window.location.href = "/dashboard";
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        background: `url(${HeroImage}) no-repeat center center fixed`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Card
        sx={{
          padding: "2rem",
          borderRadius: "19px",
          width: "441px",
        }}
      >
        <CardContent>
          <Box
            sx={{
              textAlign: "center",
              marginBottom: "2rem",
              color: colorPalette.primary,
            }}
          >
            <Box sx={{width:'50%', marginX:'auto'}}>

            <img src={HashirLogo} alt="hashir-logo" />
            </Box>
            {/*<Box sx={{fontSize:"1.6rem", fontWeight:"600", color: "#111111", fontFamily:"Outfit"}}>Multiverse Technologies</Box>*/}
            <Box
              sx={{
                color: "#111111",
                fontSize: "14px",
                marginTop: "1rem",
                fontFamily:"Outfit"
              }}
            >
              Login to your account and start upscaling your brand
            </Box>
          </Box>
          <Formik
            initialValues={initialValues}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values: typeof initialValues) => {
              handleLoginFormSubmit(values);
            }}
            validationSchema={validation}
          >
            {(formik: FormikProps<typeof initialValues>) => (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {error && (
                    <Grid item lg={12} marginBottom={2}>
                      <Alert severity="error">{error}</Alert>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid item lg={12} sm={12}>
                      <TextInput
                        placeHolder={"Username"}
                        value={formik.values.userName}
                        handleTextChange={formik.handleChange("userName")}
                        hasError={!!formik.errors.userName}
                        errorMessage={formik.errors.userName as string}
                      />
                    </Grid>
                    <Grid item lg={12} sm={12}>
                      <PasswordInput
                        placeHolder={"Password"}
                        value={formik.values.password}
                        handleTextChange={formik.handleChange("password")}
                        hasError={!!formik.errors.password}
                        errorMessage={formik.errors.password as string}
                      />
                    </Grid>
                    {/* <Grid item lg={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.rememberMe}
                          onChange={() =>
                            formik.setFieldValue(
                              "rememberMe",
                              !formik.values.rememberMe
                            )
                          }
                        />
                      }
                      label="Remember me"
                    />

                    <Box
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={handleRedirect}
                    >
                      Forgot password
                    </Box>
                  </Box>
                </Grid> */}
                  </Grid>
                  <Grid item lg={12} marginTop={"2rem"}>
                    <SubmitButton
                      title="Sign in"
                      handlePress={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

/**
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  // Map your redux state to your props here
  isLoggedIn: state.login.isLoggedIn,
  state: state.login,
});

const mapDispatchToProps = {
  logIn: loginActions.loginCall,
  reset: loginActions.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const LoginComponentRedux = connector(LoginComponent);

export { LoginComponentRedux as LoginComponent };
